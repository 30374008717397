<div class="add-subcompany" rvSpinner="add-subcompany" [rvShowSpinner]="loading">
  <form #companyForm="ngForm" id="forms.companyForm" role="form" name="forms.companyForm" (submit)="save()" autocomplete="on" novalidate>
    <common-actions
      [actions]="actions"
      [saving]="loading">
    </common-actions>

    <div id="errorBox" class="alert alert-danger" role="alert" [shown]="formError">
      <strong>{{formError}}</strong> {{apiError}}
      @if (isAddressError) {
        <suggest-general-delivery [addressObject]="company"></suggest-general-delivery>
      }
    </div>

    <company-fields [company]="company"></company-fields>
  </form>
</div>